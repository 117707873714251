<template>
  <div>
    <div
      style="
        width: 100%;
        height: 100%;
        z-index: 0;
        position: absolute;
        background-color: #c6e0d7;
      "
    ></div>
    <v-img
      style="width: 100%; height: 102%"
      :src="
        require('@/assets/background/colorful/learnWord/long_road_lake_mountain.png')
      "
      gradient="to bottom, rgba(250,250,250,.3),rgba(250,250,250,.4),rgba(250,250,250,.7), rgba(255,255,255,0.99)"
    ></v-img>
  </div>
</template>

<script>
import { setSystemColor } from "@/mixin/mixin";

export default {
  name: "learnWordBg",
  mixins: [setSystemColor],
  data() {
    return {
      timeOuts: [],
    };
  },
  methods: {
    startBg() {},
    goDark() {},
    clearTimeOut() {},
  },
  beforeDestroy() {},
  mounted() {},
  created() {
    this.setSystemStatusBarStyle(this.appThemeConfig.dark ? "light" : "dark");
  },
};
</script>

<style lang="scss" scoped></style>
