import { render, staticRenderFns } from "./learnWordIndex.vue?vue&type=template&id=a1ede298&scoped=true&"
import script from "./learnWordIndex.vue?vue&type=script&lang=js&"
export * from "./learnWordIndex.vue?vue&type=script&lang=js&"
import style0 from "./learnWordIndex.vue?vue&type=style&index=0&id=a1ede298&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1ede298",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/.pnpm/vuetify-loader@1.7.3_cfe6260f4a77386d492c4e564aa08ca3/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
installComponents(component, {VBtn,VFadeTransition,VIcon,VProgressLinear,VStepper,VStepperContent,VStepperHeader,VStepperItems,VStepperStep})
