<template>
  <div
    id="laern-word"
    :style="`height:${screenHeight}px;background-color: ${appThemeConfig.appBgColor}; padding-top: 0px`"
  >
    <div class="header" :style="`top:${isApp ? systemBarHeight + 19 : 19}px`">
      <v-btn
        class="ml-1"
        left
        width="2.7rem"
        height="2.7rem"
        fab
        small
        elevation="1"
        color="#ffffffdd"
        @click="goIndex"
      >
        <v-icon color="#1976d2">mdi-arrow-left</v-icon>
      </v-btn>

      <v-stepper
        alt-labels
        class="header-stepper rounded-xl"
        width="18rem"
        height="2.9rem"
        color="#436c58"
        flat
        v-model="stepper"
      >
        <v-stepper-header class="stepper" flat>
          <v-stepper-step
            :class="`step${step === 1 ? ' stepteal' : ''}`"
            color="teal"
            :complete="step > 1"
            :step="reviewTotal - reviewNum >= 0 ? reviewTotal - reviewNum : 0"
            complete-icon="mdi-check"
          >
            复习
          </v-stepper-step>

          <v-fade-transition>
            <div class="progress-linear" v-show="step !== 2">
              <v-progress-linear
                :value="
                  reviewTotal !== 0 ? (reviewNum / reviewTotal) * 100 : 100
                "
                color="teal"
                rounded
                height="7"
              ></v-progress-linear>
            </div>
          </v-fade-transition>

          <v-stepper-step
            :class="`step${step === 2 ? ' stepteal' : ''}`"
            color="teal"
            :complete="step > 2"
            :step="newTotal - newNum >= 0 ? newTotal - newNum : 0"
            complete-icon="mdi-check"
          >
            新词
          </v-stepper-step>

          <v-fade-transition>
            <div class="progress-linear" v-show="step !== 1">
              <v-progress-linear
                :value="newTotal !== 0 ? (newNum / newTotal) * 100 : 100"
                color="teal"
                rounded
                height="7"
              ></v-progress-linear>
            </div>
          </v-fade-transition>

          <v-stepper-step
            :class="`step${step === 3 ? ' stepteal' : ''}`"
            color="teal"
            :complete="step > 2 && flashTotal - flashNum <= 0"
            :step="flashTotal - flashNum >= 0 ? flashTotal - flashNum : 0"
            complete-icon="mdi-check"
          >
            闪过
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </div>

    <div
      class="content"
      :style="`height: calc(100% - ${
        isApp ? systemBarHeight + 80 : 80
      }px - 2.85rem);margin-top:${isApp ? systemBarHeight + 44 : 44}px`"
    >
      <!--  核心部件 -->
      <v-stepper v-model="step" class="rounded-lg step-container" flat>
        <v-stepper-items style="height: 100%">
          <v-stepper-content style="height: 100%; padding: 0" step="1">
            <v-fade-transition>
              <learn-review
                :key="firstKey"
                v-if="step === 1"
                @refresh="refresh"
                @updateTotal="updateTotal"
                @updateCount="updateCount"
                @upCount="upCount"
                @nextStep="nextStep"
                ref="learnReview"
              ></learn-review>
            </v-fade-transition>
          </v-stepper-content>

          <v-stepper-content style="height: 100%; padding: 0" step="2">
            <v-fade-transition>
              <learn-new
                v-if="step === 2"
                @refresh="refresh"
                @updateTotal="updateTotal"
                @updateCount="updateCount"
                @upCount="upCount"
                @nextStep="nextStep"
                ref="learnNew"
              ></learn-new>
            </v-fade-transition>
          </v-stepper-content>

          <v-stepper-content style="height: 100%; padding: 0" step="3">
            <v-fade-transition>
              <learn-flash
                v-if="step === 3"
                @refresh="refresh"
                @updateTotal="updateTotal"
                @updateCount="updateCount"
                @upCount="upCount"
                @nextStep="nextStep"
                ref="learnFlash"
              ></learn-flash>
            </v-fade-transition>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
    <learn-word-bg ref="bg" class="bg"></learn-word-bg>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { buttonVibrate, nosleep, setSystemColor } from "@/mixin/mixin";
import LearnWordBg from "@/views/learnWord/components/learnWordBg";
import { getDayStr } from "@/utils/common";

export default {
  name: "learnWordIndex",
  mixins: [buttonVibrate, setSystemColor, nosleep],
  components: {
    LearnNew: () => import("@/views/learnWord/components/learnNew"),
    LearnReview: () => import("@/views/learnWord/components/learnReview"),
    LearnFlash: () => import("@/views/learnWord/components/learnFlash"),
    LearnWordBg,
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      appThemeConfig: "appThemeConfig",
      systemBarHeight: "systemBarHeight",
      screenHeight: "screenHeight",
      screenWidth: "screenWidth",
      userPreferrence: "userPreferrence",
      learnTemp: "learnTemp",
    }),
    flashTotal() {
      return this.flashTotalTemp !== null
        ? this.flashTotalTemp
        : this.reviewTotal + this.newTotal;
    },
    flashNum() {
      if (
        this.dateStr === this.learnTemp.flashIndex.date &&
        this.flashTotalTemp !== null
      ) {
        return this.learnTemp.flashIndex.index;
      }
      return 0;
    },
  },
  data: () => ({
    stepper: -1,
    dateStr: getDayStr(),
    firstCreate: true,
    firstKey: 0,
    step: 1,
    reviewTotal: 0,
    reviewNum: 0,
    newTotal: 0,
    newNum: 0,
    flashTotalTemp: null,
  }),
  watch: {
    time() {
      this.zzzShort();
    },
  },
  methods: {
    refresh() {
      this.reviewTotal = 0;
      this.reviewNum = 0;
      this.newTotal = 0;
      this.newNum = 0;
      this.firstKey += 1;
      this.step = 1;
    },
    nextStep() {
      if (this.step < 3) {
        this.step += 1;
      }
    },
    updateTotal(type, num) {
      if (type === 0) {
        this.reviewTotal = num;
      }
      if (type === 1) {
        this.newTotal = num;
      }
      if (type === 2) {
        this.flashTotalTemp = num;
      }
    },
    updateCount(type, num) {
      if (type === 0) {
        this.reviewNum = num;
      }
      if (type === 1) {
        this.newNum = num;
      }
    },
    upCount(type) {
      if (type === 0) {
        this.reviewNum += 1;
      }
      if (type === 1) {
        this.newNum += 1;
      }
    },
    goBack() {
      this.zzzShort();
      this.$router.back();
    },
    goIndex() {
      this.zzzShort();
      this.$router.replace("/");
    },
    init() {
      this.$store.dispatch("setShowSysAppTab", false);
      this.$store.dispatch("setAppSystemBarColor", "#c6e0d7");
      this.setSystemFootColor(this.appThemeConfig.footerBarColer);
      this.reviewTotal = this.userPreferrence.gole.reviewWordGole;
      this.newTotal = this.userPreferrence.gole.newWordGole;
      this.$toast("离开背单词时，务必等待云同步完成，否则进度将会丢失。");
    },
  },
  activated() {
    if (this.firstCreate) {
      // 第一次创建
      this.firstCreate = false;
    } else {
      this.refresh();
      this.init();
    }
    console.info("learnWord组件被激活");
  },
  deactivated() {
    if (this.step === 1) {
      this.$refs.learnReview.syncWordProgress();
    }
    if (this.step === 2) {
      this.$refs.learnNew.syncWordProgress();
    }
    console.info("learnWord组件被暂停");
  },
  created() {
    this.init();
    console.info("learnWord组件创建");
  },
  mounted() {
    console.info("learnWord组件挂载");
  },
};
</script>

<style lang="scss" scoped>
.col {
  display: flex;
}

#laern-word {
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  background-color: #8ca0ff;
  position: relative;
  padding: 0 1.2rem 0 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
    align-items: flex-end;

    .step-container {
      margin: 0 auto;
      height: 100%;
      width: 100%;
      background-color: #ffffff00;

      ::v-deep .v-stepper__wrapper {
        height: 100%;
      }
    }
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    position: relative;
    flex-wrap: nowrap;
    font-size: 0.7rem;

    .stepteal {
      ::v-deep .v-stepper__step__step {
        background-color: #009688 !important;
        border-color: #009688 !important;
      }
    }

    @media only screen and (max-width: 959px) {
      ::v-deep .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
        display: unset;
        color: #aaaaaa;
        font-size: 1.05rem;
        transform: scale(0.6);
        -webkit-transform: scale(0.6);
      }
    }

    ::v-deep .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
      display: unset;
      color: #aaaaaa;
      font-size: 1.05rem;
      transform: scale(0.6);
      -webkit-transform: scale(0.6);
    }

    ::v-deep .v-stepper__step__step {
      font-size: 0.7rem;
      height: 1.3rem;
      min-width: 1.3rem;
      width: 1.3rem;
      margin-bottom: 0;
    }

    .header-stepper {
      display: flex;
      align-items: center;
      background-color: #ffffff70;

      .progress-linear {
        flex: 1 1 3rem;
        margin: 0 0.3rem;
      }

      .stepper {
        width: 100%;
        height: 100%;
        padding: 0 0.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .step {
          flex-basis: 2.5rem;
          padding: 2px 0 0 0 !important;
          margin: 0 !important;

          ::v-deep .v-stepper__step__step .v-icon.v-icon {
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  .dialog {
    z-index: 3;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .bg {
    width: 100%;
    height: 100%;
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
